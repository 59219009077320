
import _ from 'lodash';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import EpPerusteContent from '@shared/components/EpPerusteContent/EpPerusteContent.vue';
import OppiaineenVuosiluokka from './OppiaineenVuosiluokka.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';

@Component({
  components: {
    EpPerusteContent,
    OppiaineenVuosiluokka,
    EpContentViewer,
  },
})
export default class OppiaineenVuosiluokkakokonaisuus extends Vue {
  @Prop({ required: true })
  private tietue!: any;

  @Prop({ required: true })
  private kuvat!: any[];

  @Prop({ required: true })
  private termit!: any[];

  get perusteenVuosiluokkakokonaisuus() {
    return this.tietue.perusteenOppiaineenVlk;
  }

  get oppiaineenVuosiluokkakokonaisuus() {
    return this.tietue.oppiaineenVuosiluokkakokonaisuus;
  }

  get oppiaineenPohjanVuosiluokkakokonaisuus() {
    return this.tietue.oppiaineenPohjanVuosiluokkakokonaisuus || {};
  }

  get pohjanVuosiluokat() {
    return _.keyBy(this.oppiaineenPohjanVuosiluokkakokonaisuus.vuosiluokat, 'vuosiluokka');
  }

  get vuosiluokkakokonaisuus() {
    return this.tietue.vuosiluokkakokonaisuus;
  }

  get oppiaine() {
    return this.tietue.oppiaine;
  }

  get vuosiluokat() {
    return _.sortBy(this.oppiaineenVuosiluokkakokonaisuus.vuosiluokat, 'vuosiluokka');
  }

  hasTekstiContent(object, key) {
    return object != null && object[key] != null && object[key].teksti != null;
  }
}

