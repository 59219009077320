
import _ from 'lodash';
import { Vue, Component, Prop } from 'vue-property-decorator';
import EpButton from '@shared/components/EpButton/EpButton.vue';
import EpCollapse from '@shared/components/EpCollapse/EpCollapse.vue';
import EpOrderColorBall from '@shared/components/EpColorIndicator/EpOrderColorBall.vue';
import EpContentViewer from '@shared/components/EpContentViewer/EpContentViewer.vue';
import EpArvioinninkohteetTable from '@shared/components/EpArvioinninkohteetTable/EpArvioinninkohteetTable.vue';
import TavoitteenSisaltoalueet from './TavoitteenSisaltoalueet.vue';

@Component({
  components: {
    EpButton,
    EpArvioinninkohteetTable,
    EpContentViewer,
    EpOrderColorBall,
    EpCollapse,
    TavoitteenSisaltoalueet,
  },
})
export default class OppiaineenVuosiluokka extends Vue {
  @Prop({ required: true })
  private oppiaineenVuosiluokka!: any;

  @Prop({ required: false })
  private pohjaOppiaineenVuosiluokka!: any;

  @Prop({ required: false })
  private valinnainen!: boolean;

  @Prop({ required: true })
  private kuvat!: any[];

  @Prop({ default: true })
  private naytaSisaltoalueet!: boolean;

  @Prop({ default: true })
  private naytaArviointikriteerit!: boolean;

  @Prop({ default: true })
  private naytaLaajaAlaisetOsaamiset!: boolean;

  @Prop({ default: false })
  private avaaSuljeSiirrettavissa!: Boolean;

  tavoitteetAvattu = false;

  get tavoitteet() {
    return _.map(this.oppiaineenVuosiluokka?.tavoitteet, tavoite => {
      return {
        ...tavoite,
        tavoite: this.$kaanna(tavoite.tavoite)
          .replace('<p>', '')
          .replace('</p>', ''),
      };
    });
  }

  get tavoitteetAlueilla() {
    if (_.size(this.tavoitealueet) > 0) {
      return [
        ..._.map(this.tavoitealueet, tavoitealue => {
          return {
            nimi: tavoitealue.nimi,
            tavoitteet: _.filter(this.tavoitteet, tavoite => _.find(tavoite.kohdealueet, { nimi: tavoitealue.nimi })),
          };
        }),
      ];
    }
    else {
      return [{ nimi: '', tavoitteet: this.tavoitteet }];
    }
  }

  get pohjanTavoitteet() {
    return _.keyBy(this.pohjaOppiaineenVuosiluokka?.tavoitteet, 'tunniste');
  }

  toggleTavoite() {
    this.tavoitteetAvattu = !this.tavoitteetAvattu;
    _.forEach(this.$refs.tavoitecollapse, (tavoite: any) => tavoite.toggle(this.tavoitteetAvattu));
  }

  get tavoitealueet() {
    return _.chain(this.tavoitteet)
      .map('kohdealueet')
      .flatten()
      .uniqBy('nimi')
      .value();
  }
}

