
import _ from 'lodash';
import { Prop, Component, Vue, Watch } from 'vue-property-decorator';
import { Meta } from '@shared/utils/decorators';
import { Kielet } from '@shared/stores/kieli';
import { onkoUusi } from '@shared/utils/tiedote';
import EpSpinnerSlot from '@shared/components/EpSpinner/EpSpinnerSlot.vue';
import { PerusteStore } from '@/stores/PerusteStore';
import { TiedoteStore } from '@/stores/TiedoteStore';
import { TietoapalvelustaStore } from '@/stores/TietoapalvelustaStore';
import { JulkaistutKoulutustyypitStore } from '@/stores/JulkaistutKoulutustyypitStore';
import { BrowserStore } from '@shared/stores/BrowserStore';
import EpEtusivuHaku from '@/routes/home/EpEtusivuHaku.vue';
import KoulutustyyppiTile from '@/routes/home/KoulutustyyppiTile.vue';
import InfoTile from '@/routes/home/InfoTile.vue';
import { kansallisetOsaamismerkitRoute, navigoitavatKoulutustyyppiRyhmat, ophMaarayksetRoute, otherLinks, navigoitavatMuutRyhmat } from '@/utils/navigointi';
import EpJulkiLista from '@shared/components/EpJulkiLista/EpJulkiLista.vue';
import { TiedoteDto } from '@shared/api/eperusteet';
import { OsaamismerkitStore } from '@/stores/OsaamismerkitStore';
import { digitaalinenOsaaminen } from '@shared/utils/perusteet';

@Component({
  components: {
    EpJulkiLista,
    InfoTile,
    KoulutustyyppiTile,
    EpEtusivuHaku,
    EpSpinnerSlot,
  },
})
export default class RouteHome extends Vue {
  @Prop({ required: true })
  private perusteStore!: PerusteStore;

  @Prop({ required: true })
  private tiedoteStore!: TiedoteStore;

  @Prop({ required: true })
  private julkaistutKoulutustyypitStore!: JulkaistutKoulutustyypitStore;

  @Prop({ required: true })
  private tietoapalvelustaStore!: TietoapalvelustaStore;

  @Prop({ required: true })
  private osaamismerkitStore!: OsaamismerkitStore;

  private browserStore = new BrowserStore();

  async mounted() {
    await this.osaamismerkitStore.fetchKategoriat({ poistunut: false });
  }

  @Watch('julkaistutKoulutustyypit')
  async julkaistutKoulutustyypitChange() {
    await this.tiedoteStore.getUusimmat(this.sisaltoKieli, _.map(this.julkaistutKoulutustyypitStore.koulutustyyppiLukumaarat.value, 'koulutustyyppi'));
  }

  avaaTiedote(tiedote: TiedoteDto) {
    this.$router.push({
      name: 'uutinen',
      params: {
        tiedoteId: '' + tiedote.id,
      },
    });
  }

  get julkaistutKoulutustyypit() {
    return this.julkaistutKoulutustyypitStore.julkaistutKoulutustyypit.value;
  }

  @Watch('sisaltoKieli')
  async sisaltoKieliChange() {
    await this.mounted();
  }

  get sisaltoKieli() {
    return Kielet.getSisaltoKieli.value;
  }

  get tiedotteet() {
    return this.tiedoteStore.uusimmatTiedotteet;
  }

  get tiedotteetMapped() {
    return _.chain(this.tiedotteet)
      .map(tiedote => {
        return {
          ...tiedote,
          uusi: onkoUusi((tiedote as any).luotu),
          perusteNimi: tiedote.perusteet && tiedote.perusteet.length === 1 ? this.$kaanna(tiedote.perusteet[0].nimi) : null,
          koulutustyyppi: tiedote.koulutustyypit && tiedote.koulutustyypit.length === 1 ? this.$t(tiedote.koulutustyypit[0]) : null,
        };
      })
      .take(this.browserStore.window.value.width > 991 ? 10 : 3)
      .value();
  }

  get digitaalinenOsaaminenPeruste() {
    return _.first(this.julkaistutKoulutustyypitStore.digitaalinenOsaaminen.value);
  }

  get koulutustyyppiItems() {
    return navigoitavatKoulutustyyppiRyhmat(this.julkaistutKoulutustyypitStore.julkaistutKoulutustyypit.value as any);
  }

  get otherItems() {
    return navigoitavatMuutRyhmat(this.osaamismerkitStore.kategoriat.value as any, this.digitaalinenOsaaminenPeruste ?? {});
  }

  get tietoapalvelusta() {
    return this.tietoapalvelustaStore.tietoapalvelusta.value;
  }

  get infoLinkit() {
    return [
      ...(this.tietoapalvelusta ? [this.tietoapalvelusta] : []),
      ...otherLinks(),
    ];
  }

  ajankohtaistaUrl() {
    return `${window.location.origin}/#/${this.sisaltoKieli}/ajankohtaista`;
  }

  @Meta
  getMetaInfo() {
    return {
      title: this.$t('eperusteet'),
      titleTemplate: null,
    };
  }
}
